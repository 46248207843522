@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
.section5 {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.tokenomics {
  width: 600px;
  height: auto;
}
.section5_body_title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 80%;
}
.section5_body_title_text {
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 121.5%;
  color: #ffffff;
  font-family: "Poppins", sans-serif;

  padding: 20px 50px 10px 50px;
  background: rgba(55, 55, 55, 0.74);
  border-radius: 0px 30px 30px 0px;
}
.section5_body_title_text div {
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  text-transform: uppercase;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}
.learn {
  margin-top: 80px;
  width: 300px;
  height: auto;
}
.section5_body_title_img img {
  width: 400px;
  height: auto;
}
@media screen and (max-width: 1200px) {
  .tokenomics {
    width: 100%;
    height: auto;
  }
  .section5_body_title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    width: 80%;
  }
  .section5_body_title_img img {
    width: 100%;
    height: auto;
  }
  .section5_body_title_text {
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 121.5%;
    color: #ffffff;
    padding: 20px;
  }
}
