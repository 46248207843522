.section3 {
  margin-top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.showcase {
  width: 600px;
  height: auto;
}
.slick-list {
  width: 800px;
  height: 400px;
  position: relative;
  margin-bottom: 100px;
  text-align: center;
}
.slick-list div img {
  width: 400px;
  height: auto;
}
@media screen and (max-width: 350px) {
  .slick-list {
    width: 280px !important;
    height: 200px;
    position: relative;
    margin-bottom: 100px;
    text-align: center;
  }
  .slick-list div img {
    width: 150px !important;
    height: auto;
  }
}
@media screen and (max-width: 1200px) {
  .section1_bkg {
    width: 100%;
    height: auto;
  }
  .showcase {
    width: 100%;
    height: auto;
  }
  .slick-list {
    width: 300px;
    height: 200px;
    position: relative;
    margin-top: 80px;
    margin-bottom: 20px;
    text-align: center;
  }
  .slick-list div img {
    width: 150px;
    height: auto;
  }
}
