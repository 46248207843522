@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
.section8 {
  background-color: black;
  text-align: center;
  margin-top: 100px;
}
.become {
  width: 80%;
}
.socials_footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 300px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.section8 p {
  margin-top: 80px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 20.28px;
  line-height: 30px;
  text-align: center;
  padding-bottom: 20px;
  color: #ffffff;
}
@media screen and (max-width: 1200px) {
  .socials_footer img {
    margin-top: 50px;
    width: 70px;
    height: auto;
  }
  .socials_footer {
    width: 200px;
  }
  .section8 p {
    font-size: 14px;
    line-height: 30px;
  }
}
