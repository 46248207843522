.section1_bkg {
  width: 100%;
  height: 100vh;
}
@media screen and (max-width: 1200px) {
  .section1_bkg {
    width: 100%;
    height: auto;
  }
}
