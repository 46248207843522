@import url("https://fonts.googleapis.com/css2?family=Changa+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
.section2_body {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.section2_body_socials {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row;
  background-color: #00fff1;
  height: 100px;
  width: 100%;
  padding-top: 10px;
  position: relative;
  bottom: 10px;
}
.section2_body_socials div img {
  width: 220px;
  height: auto;
}
.section2_body_title {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  flex-direction: row;
  position: relative;
  margin-top: 100px;
  width: 80%;
}
.section2_body_title_img img {
  height: 500px;
  width: auto;
}
.section2_body_title_text {
  height: 437px;
  text-align: left;
  color: white;
  font-size: 20px;
  line-height: 121.5%;
  text-align: justify;
  letter-spacing: -0.03em;
  padding: 20px 50px 10px 50px;
  background: rgba(55, 55, 55, 0.74);
  border-radius: 0px 30px 30px 0px;
  font-family: "Poppins", sans-serif;
}
.section2_body_title_text div {
  font-weight: 400;
  font-size: 63.8944px;
  line-height: 102.9%;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Changa One", cursive !important;
}
@media screen and (max-width: 1200px) {
  .section2_body_socials {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #00fff1;
    height: 50px;
    width: 100%;
    padding-top: 10px;
    position: relative;
    bottom: 10px;
  }
  .section2_body_socials div img {
    width: 130px;
    height: auto;
  }
  .section2_body_title_img {
    display: none;
  }
  .section2_body_title_text {
    height: auto;
    padding: 20px;
  }
  .section2_body_title {
    width: 90%;
  }

  .section2_body_title_text {
    font-size: 18px;
  }
  .section2_body_title_text div {
    font-size: 50.8944px;
  }
}
