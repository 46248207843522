@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap");
.section7 {
  padding-top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.team {
  width: 600px;
  height: auto;
}

.teams_list {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.team_list_individual {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  cursor: pointer;
}
.team_name {
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  font-size: 25.27px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #ffffff;
}

.team_postn {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 16.8467px;
  line-height: 120%;
  /* or 20px */

  letter-spacing: 0.01em;

  color: #ffffff;
}
.linkedin {
  width: 30px;
  height: auto;
}

@media screen and (max-width: 1200px) {
  .teams_list {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
  .team {
    width: 100%;
    height: auto;
  }
}
