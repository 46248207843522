@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Changa+One&display=swap");
.section6 {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.faqs {
  width: 600px;
  height: auto;
}
.MuiPaper-root {
  background: rgba(103, 26, 87, 0.92) !important;
  box-shadow: 6px 6px 0px #400834 !important;
  border-radius: 15px !important;
  color: white !important;
  margin-top: 20px;
}
.css-ahj2mt-MuiTypography-root {
  font-family: "Changa One", cursive;
}
.answer {
  font-family: "Libre Franklin", sans-serif !important;
}
@media screen and (max-width: 1200px) {
  .faqs {
    width: 100%;
    height: auto;
    text-align: center;
  }
  .MuiPaper-root {
    width: 90%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
}
