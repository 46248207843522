.container {
  align-items: center;
  display: flex;
  justify-content: center;
}
.item {
  align-items: center;

  display: flex;
  justify-content: center;
  position: relative;

  z-index: 1000;
  .person {
    object-fit: cover;
    height: 300px;
    width: 300px;
  }
  &::before,
  &::after {
    background-image: linear-gradient(-180deg, #d53d13 0%, #d53d13 97%);
    content: "";
    height: 100%;
    position: absolute;
    transition: all 0.2s;
    transition-delay: 0.2s;
    transform: translateY(0px) translateX(0px);
    width: 100%;
    z-index: -1;
    width: 0;
    height: 0;
    // border-radius: 90px 0px 0px 90px;
  }

  &::after {
    background-image: linear-gradient(0deg, #d260ff 0%, #d260ff 97%);
    width: 0;
    height: 0;
  }
  &:hover {
    animation-name: hoverPop;
    animation-duration: 0.6s;

    animation-fill-direction: forward;
    .person {
    }
    &::before {
      animation: slick-hover-2 0.6s 0.6s linear;
      transform: translateY(-20px) translateX(-20px);
      //   transform: skewY(-5deg);
      width: 260px;
      height: 260px;
      //   border-radius: 90px 0px 0px 90px;
    }
    &::after {
      animation: slick-hover 0.6s 0.6s linear;
      transform: translateY(20px) translateX(20px);
      //   transform: skewY(-7deg);
      width: 260px;
      height: 260px;
    }
  }
}

// @keyframes hoverPop {
//   0%,
//   100% {
//     transform: scale(1);
//   }
//   33% {
//     transform: scale(1.05);
//   }
//   66% {
//     transform: scale(0.9);
//   }
// }
@keyframes slick-hover {
  0.00% {
    transform: translateY(20px) translateX(20px);
  }
  16.67% {
    transform: translateY(-13px) translateX(-9px);
  }
  33.33% {
    transform: translateY(4px) translateX(12px);
  }
  50.00% {
    transform: translateY(20px) translateX(-20px);
  }
  66.67% {
    transform: translateY(-3px) translateX(8px);
  }
  83.33% {
    transform: translateY(-15px) translateX(-11px);
  }
  100.00% {
    transform: translateY(20px) translateX(20px);
  }
}

@keyframes slick-hover-2 {
  0.00% {
    transform: translateY(-20px) translateX(-20px);
  }
  16.67% {
    transform: translateY(3px) translateX(-5px);
  }
  33.33% {
    transform: translateY(18px) translateX(-10px);
  }
  50.00% {
    transform: translateY(-20px) translateX(20px);
  }
  66.67% {
    transform: translateY(15px) translateX(-4px);
  }
  83.33% {
    transform: translateY(-1px) translateX(15px);
  }
  100.00% {
    transform: translateY(-20px) translateX(-20px);
  }
}
