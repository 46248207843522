@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
.section4 {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.roadmap {
  width: 600px;
  height: auto;
}
.roadmap_list {
  display: flex;
  margin-top: 50px;

  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.roadmap_list div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.box_text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: left !important;
  align-items: left !important;
  flex-direction: column;
  width: 90%;
}
.box_text div {
  font-style: normal;
  font-weight: 800;
  font-size: 33px;
  line-height: 120%;
  text-align: left !important;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
}
.roadmap_text {
  position: relative;
}
.box_text ul li {
  text-align: left;
  font-weight: 400;
  font-size: 18px;
  line-height: 127%;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 350px) {
  .roadmap {
    width: 100%;
    height: auto;
  }
  .roadmap_img {
    display: none !important;
  }
  .roadmap_list div {
    width: 100%;
  }
  .roadmap_text img {
    width: 100%;
  }
  .box_text {
    width: 50%;
  }
  .box_text div {
    font-size: 12px !important;
    line-height: 120%;
  }
  .box_text ul li {
    font-size: 7px !important;
    width: 90%;
    line-height: 127%;
  }
}

@media screen and (max-width: 900px) {
  .roadmap {
    width: 100%;
    height: auto;
  }
  .roadmap_img {
    display: none !important;
  }
  .roadmap_list div {
    width: 100%;
  }
  .roadmap_text img {
    width: 100%;
  }
  .box_text {
    width: 50%;
  }
  .box_text div {
    font-size: 16px !important;
    line-height: 120%;
  }
  .box_text ul li {
    font-size: 10px !important;
    width: 90%;
    line-height: 127%;
  }
}

@media screen and (max-width: 1200px) {
  .roadmap {
    width: 100%;
    height: auto;
  }
  .roadmap_img {
    display: none !important;
  }
  .roadmap_list div {
    width: 100%;
  }
  .roadmap_text img {
    width: 90%;
  }
  .box_text {
    width: 50%;
  }
  .box_text div {
    font-size: 30px;
    line-height: 120%;
    position: relative;
    top: 10px;
  }
  .box_text ul li {
    font-size: 20px;
    width: 90%;
    line-height: 127%;
  }
}
